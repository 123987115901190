// @flow 
import * as React from 'react';
import {toast, Toaster} from "react-hot-toast";

import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import 'src/Style/Eva/Xuaii/xuaiiTribe.scss';
import {api, apiEva, apiEvaLocal} from "../../utils/api";
import {worldviewXuaii, AreaDataXuaiiGrupal, AreaType,CompetencyDataXuaiiGrupal} from "./types";
import {getToken, getUser, UserInterface} from "../../utils/user";
import FilterXuaii from "./Componets/FilterXuaii";
import {Card, Col, Row} from "react-bootstrap";
import Chart from "react-apexcharts";
import bar from "./Chart/chartXuaiiArea";
import barWorldView from './Chart/worldViewXuaii';
import Excel from "../utils/Excel";
import FilterProgress from "./Componets/FilterProgress";
import useCurrentYear from "./Componets/UseCurrentYear";
import {useDispatch} from "react-redux";
import {useSelector} from "../../store/reducer";
import OptionsFilter from "./Componets/OptionsFilter";
import {
    setAreaGroup,
    setCompetencyGroup,
    setEnglishGroup
} from "../../redux/actions";
import useCoordi from "../Teacher/useCoordi";
import useGeneralData from "./Componets/useGeneralData";

interface Props {
    idGroup?:React.SetStateAction<any>
    groupSede?:React.SetStateAction<any>
    setUpdateGroup?:React.SetStateAction<any>
    institutions?:React.SetStateAction<any>
    setCampus?:React.SetStateAction<any>
    updateGroup?:React.SetStateAction<any>
    setIndividual?:React.SetStateAction<any>
    individual?:React.SetStateAction<boolean>
    setInstitutions?:React.SetStateAction<any>
    groupId?:React.SetStateAction<any>
}

const TribeXuaii = ({idGroup,groupSede,setUpdateGroup,institutions,setCampus,updateGroup,setIndividual,individual,setInstitutions, groupId}:Props) => {
    const test = useSelector((state) => state.GroupXuaiiReducer)
    const dispatch = useDispatch();

    //REDUX =====================================
    const Redux = useSelector((state) => state.DataRoles)
    //===========================================

    const {
        currentYear
    } = useCurrentYear();
    const [year,setYear] = useState<number>(0);
    const [value,setValue] = useState<string>("entry");

    //user
    const [user, setUser] = React.useState<UserInterface>();

    //Bool AREA COMPETENCY==========================================>
    const [buttonBool1,setButtonBool1] = useState<number>(0);
    //Bool AREA COMPETENCY <==========================================

    const [dataTopic,setData] = useState<[AreaDataXuaiiGrupal]>([
        {
            topic: "",
            correctPerformance: 0,
            incorrectPerformance: 0
        }
    ]);
    const [area,setArea] = useState({
        options: {
            xaxis: {
                categories: [],
                title:{
                    text: 'Competencia',
                    style:{
                        fontWeight: 200,
                        fontSize: '15px',

                    }
                }
            }
        }
    });
    const [series,setSeries] = useState<ApexAxisChartSeries>();


    //competency

    //ChartCompetency
    const [dataCompentecy,setDataCompetency] = useState<[CompetencyDataXuaiiGrupal]>([{
        competency: '',
        grade: '',
        area: '',
        correct_performance: 0,
        incorrect_performance: 0,
        count: 0
    }]);
    const [matematicas,SetMatematicas] = useState<ApexAxisChartSeries>();
    const [lenguaje,Setlenguaje] = useState<ApexAxisChartSeries>();
    const [naturales,SetNaturales] = useState<ApexAxisChartSeries>();
    const [are4,SetAre4] = useState<ApexAxisChartSeries>();
    const [optionslenguaje,setOptionslenguaje] = useState({
        options: {
            xaxis: {
                categories: [],
                title:{
                    text: 'Competencia',
                    style:{
                        fontWeight: 200,
                        fontSize: '15px',

                    }
                }
            }
        }
    });
    const [optionsMatematica,setOptionsMatematica] = useState({
        options: {
            xaxis: {
                categories: [],
                title:{
                    text: 'Competencia',
                    style:{
                        fontWeight: 200,
                        fontSize: '15px',

                    }
                }
            }
        }
    });
    const [optionsNaturales,setOptionsNaturales] = useState({
        options: {
            xaxis: {
                categories: [],
                title:{
                    text: 'Competencia',
                    style:{
                        fontWeight: 200,
                        fontSize: '15px',

                    }
                }
            }
        }
    });
    const [optionsAre4,setOptionsAre4] = useState({
        options: {
            xaxis: {
                categories: [],
                title:{
                    text: 'Competencia',
                    style:{
                        fontWeight: 200,
                        fontSize: '15px',

                    }
                }
            }
        }
    });

    //Worldview
    const [worldview,setWorldview] = useState<worldviewXuaii>();

    const [Listening,setListening] = useState<ApexAxisChartSeries>();
    const [general,setGeneral] = useState<ApexAxisChartSeries>();
    const [reading,setReading] = useState<ApexAxisChartSeries>();
    const [worldviewCategories,setWorldviewCategories] = useState({ options: {
            xaxis: {
                categories: ['PRE A1','A1','A2','B1','B2'],
                title: {
                    text: 'Nivel',
                    style:{
                        fontWeight: 200,
                        fontSize: '15px',

                    }
                },
            },
        },});

    const [loading,setLoading]= useState<boolean>(true);
    const [loading2,setLoading2]= useState<boolean>(true);
    const [loadingText,setLoadingText] = useState('');
    const [institutionPeriod, setInstitutionPeriod] = useState(0);
    const [isChangedInstitutionPeriod, setInstitutionPeriodState] = useState(false);
    const [queryGroup, setGroupQuery] = useState(0);
    const {
        data,
    } = useCoordi();

    const {
        // groupSede,
        setGegree,
        // institutions,
        setInstitution,
    } = useGeneralData();

    const headers = {
        "access-token": getToken(),
    };

    function getMentors(id: number) {
        api
            .get(`/group_mentors_user/mentors/group/${id}`, {headers})
            .then((response) => {
                setUser(response.data[0].user);
            })
            .catch((error) => {
            });
    }
    function getMentorsV2(id: number) {
        api
            .get(`/group_mentors_user/mentors/group/${id}`, {headers})
            .then((response) => {
                setUser(response.data[0].user);
                return response.data[0].user;
            })
            .catch((error) => {
            });
    }

    React.useEffect(() => {
        const user: UserInterface = getUser();
        if (Redux.degree?.id)getMentors(Redux.degree?.id);
        else if (user.roles === "Mentor")setUser(user);
    }, []);

    useEffect(() => {
        if (updateGroup?.id !== 0){
            getMentors(updateGroup?.id);
        }
    }, [updateGroup]);

    const [idividualEnable,setIdividualEnable] = React.useState<boolean>(false);

    useEffect(()=>{
        const Year = currentYear[currentYear.length -1]
        setYear(Year);
    },[currentYear])


    useEffect(() => {
        if (year === 0 || year === undefined) return;

        setLoading(true);
        setLoadingText('Espera, cargando datos.');

        let period = 0
        if(year === 2025 && value === 'output') {
            period = 5
        }else if(year === 2025 && value === 'entry'){
            period = 4
        }else if(year === 2024 && value === 'output') {
            period = 3
        }else if(year === 2024 && value === 'entry'){
            period = 2
        }else if(year === 2023 && value === 'output'){
            period = 1
        }else{
            period = 3
        }

        if(data.institution?.id !== 0){
            apiEva.get("institution-periods/data/institution/"+data.institution?.id+"/period/"+period).then((response) => {
                setInstitutionPeriod(response.data);
                setInstitutionPeriodState(true);
            }).catch(() => {
                setLoading(true);
                setLoadingText('Sin datos disponibles.');
            })

        }else if (user?.id !== undefined){
            apiEva.get("/viajeros-xuaii/coordinador/"+user?.id).then((response) => {
                const institution = response.data.institutionId;
                apiEva.get("institution-periods/data/institution/"+institution+"/period/"+period).then((response) => {
                    setInstitutionPeriod(response.data);
                    setInstitutionPeriodState(true);
                }).catch(() => {
                    setLoading(true);
                    setLoadingText('Sin datos disponibles.');
                })
            }).catch(() => {
                setLoading(true);
                setLoadingText('Sin datos disponibles.');
            })
        }


    }, [user, year, value])


    useEffect(()=>{

        setLoading(true);
        setLoading2(true);
        setLoadingText('Sin datos disponibles.');

        if (user?.username !== undefined ){
            if (year === 0 || year === undefined) return;
            setInstitutionPeriodState(true);
            setLoadingText('Espera, cargando datos.');
            user.roles !== "Mentor" && setIdividualEnable(false);
            const params = {
                userId:  user?.id,
                type:value,
                year:year,
                period: institutionPeriod
            }

            if(queryGroup === 0){
                api.get("group_mentors_user/getGroup/"+user?.id).then((response) => {
                    setGroupQuery(response.data.group.id)
                }).catch(() => {
                    setInstitutionPeriodState(false);
                    setLoading(true);
                    setLoadingText('Sin datos disponibles.');
                })
            }

            // if (Object.keys(test.areaGroup).length !== 0 && test.areaGroup?.[user?.id]?.[year]?.[value] && buttonBool1 === 0 ) {
            if (false) {
                // const data = test.areaGroup[user?.id][year][value];
                // setData(data);
                // setLoading(false);
                // user.roles !== "Mentor" && setIdividualEnable(true);
            }
            else if (buttonBool1 === 0 ){
                if(params.period !== 0){
                    apiEva
                        .patch<[AreaDataXuaiiGrupal]>('responses-xuaii/mentor/topic/performance/',params)
                        .then((response)=>{
                            const data = response.data
                            setData(data);
                            setLoading(false);
                            dispatch(setAreaGroup(data,user.id,year,value));
                            user.roles !== "Mentor" && setIdividualEnable(true);
                        })
                        .catch(()=>{
                            setInstitutionPeriodState(false);
                            setLoading(true);
                            setLoadingText('Sin datos disponibles.');
                            toast.error(`Este grupo no tiene datos, \nPrueba con otro`,{
                                position:"bottom-right"
                            })
                            // user.roles !== "Mentor" && setIdividualEnable(false);
                            // setLoadingText('Sin datos disponibles.');
                        })

                }
            }
            if (Object.keys(test.competencyGroup).length !== 0 && test.competencyGroup?.[user?.id]?.[year]?.[value] && buttonBool1 === 1 ) {
                const data = test.competencyGroup[user?.id][year][value];
                setDataCompetency(data);
                setLoading2(false);
                user.roles !== "Mentor" && setIdividualEnable(true);
            }
            else if (buttonBool1 === 1){
                apiEva
                    .patch<[CompetencyDataXuaiiGrupal]>('responses-xuaii/mentor/competency/performance/',params)
                    .then((response)=>{
                        const data = response.data
                        setDataCompetency(data);
                        setLoading2(false);
                        dispatch(setCompetencyGroup(data,user.id,year,value));
                        user.roles !== "Mentor" && setIdividualEnable(true);
                    })
                    .catch(()=>{
                        setInstitutionPeriodState(false);
                        setLoading(true);
                        setLoadingText('Sin datos disponibles.');
                        toast.error(`Este grupo no tiene datos, \nPrueba con otro`,{
                            position:"bottom-right"
                        })
                    })
            }
            if (Object.keys(test.englishGroup).length !== 0 && test.englishGroup?.[user?.id]?.[year]?.[value] && buttonBool1 === 2 ) {
                const data = test.englishGroup[user?.id][year][value];
                setWorldview(data);
                setLoading(false);
                user.roles !== "Mentor" && setIdividualEnable(true);
            }
            else if (buttonBool1 === 2){
                apiEva
                    .patch<worldviewXuaii>('responses-xuaii/mentor/worldview/performance/',params)
                    .then((response)=>{
                        const data = response.data
                        setWorldview(data);
                        setLoading(false);
                       dispatch(setEnglishGroup(data,user.id,year,value));
                        user.roles !== "Mentor" && setIdividualEnable(true);
                    })
                    .catch(()=>{
                        setInstitutionPeriodState(false);
                        setLoading(true);
                        setLoadingText('Sin datos disponibles.');
                        toast.error(`Este grupo no tiene datos, \nPrueba con otro`,{
                            position:"bottom-right"
                        })
                    })
            }
            setInstitutionPeriodState(false);
        }

    },[institutionPeriod, user?.id,isChangedInstitutionPeriod,queryGroup,buttonBool1])


    useEffect(()=>{

        const Data = [];
        const categorie:any = [];

        for (const item of dataTopic) {
            Data.push(Math.round(item.correctPerformance));
            const areaName = item.topic.split('_')
            if(areaName[0] === 'Matematicas') categorie.push('Matemáticas');
            else  categorie.push(areaName[0].split(' '));
        }
        const dataSeries:ApexAxisChartSeries =[
            {name:"%",data: Data}
        ]

        setArea(()=>({
            options: {
                xaxis: {
                    categories: categorie,
                    title:{
                        text: 'Área',
                        style:{
                            fontWeight: 200,
                            fontSize: '15px',

                        }
                    }
                }
            }
        }))
        setSeries(dataSeries)

    },[dataTopic])

    useEffect(()=>{
        const processData = (area:string, data:React.SetStateAction<any>, competency:string, setState:React.SetStateAction<any>, optionsState:React.SetStateAction<any>) => {
            const values = [];
            const competencyValues = [];

            for (const item of data) {
                if (item.area === area) {
                    values.push(Math.round(item.correct_performance));
                    competencyValues.push(item.competency.split(' '));
                }
            }

            if (values.length < 2 && values.length > 0) {
                values.push(0);
            }

            const series = [{ name: area, data: values }];
            setState(series);
            optionsState({
                options: {
                    xaxis: {
                        categories: competencyValues,
                        title: {
                            text: 'Competencia',
                            style:{
                                fontWeight: 200,
                                fontSize: '15px',

                            }
                        },
                    },
                },
            });
        };

        const areas = ['Matematicas', 'Lenguaje', 'Ciencias Naturales', 'Competencias Ciudadanas'];
        const stateVariables = [
            [SetMatematicas, setOptionsMatematica],
            [Setlenguaje, setOptionslenguaje],
            [SetNaturales, setOptionsNaturales],
            [SetAre4, setOptionsAre4],
        ];

        areas.forEach((area, index) => {
            const [setState, optionsState] = stateVariables[index];
            processData(area, dataCompentecy, "", setState, optionsState);
        });

    },[dataCompentecy])

    //COORDINADOR ROL ====================>
    useEffect(() => {
        console.log('idGroup')
        console.log(idGroup)
        console.log(groupId)
        if (idGroup != undefined ){
            setUser({
                roles:"Coordinador",
                username: idGroup[0]?.user?.fullName,
                id: idGroup[0]?.user?.id
            });
        }else if (groupId != undefined ){
            api
                .get(`/group_mentors_user/mentors/group/${groupId}`, {headers})
                .then((response) => {
                    console.log(response.data);
                    const user = response.data[0].user;
                    setUser({
                        roles:"Coordinador",
                        username: user?.fullName,
                        id: user?.id
                    });
                })
                .catch((error) => {
                });
        }

    ;
    }, [idGroup]);
    //COORDINADOR ROL <====================

    useEffect(()=>{

        const stateVariables = {
           "listening_and_Vocabulary": setListening,
            "general":setGeneral,
            "reading":setReading,
        };
               for (const key in worldview) {
                   let value = worldview[key]
                   let series = [];
                   let state = stateVariables[key as keyof typeof stateVariables];

                   for (const valueKey in value) {
                       series.push(value[valueKey])
                   }
                   state([{name:key,data:series}])


               }
    },[worldview])

    return (
        <>
            <FilterProgress
                setYear={setYear}
                setValue={setValue}
                groupSede={groupSede}
                setUpdateGroup={setUpdateGroup}
                institutions={institutions}
                setCampus={setCampus}
            />
            <div className="BodyXuaiiTribe" id="">
            <OptionsFilter
                style="Left-Nav"
                setButtonBool1={setButtonBool1}
                english={true}
                year ={year}
                value ={value}
            />

            <Col >
                {/*
                    <br/>
                    <FilterHistoryXuaii
                        setYear={setYear}
                        setValue={setValue}
                        groupSede={groupSede}
                        setUpdateGroup={setUpdateGroup}
                        institutions={institutions}
                        setCampus={setCampus}
                        setInstitutions={setInstitutions}
                    />
                    */
                   }
                {
                    buttonBool1 == 0?
                        series?(
                            <>

                                <br/>
                                <Card  className="shadow-none" style={{maxWidth: 450 , margin:"auto"}}>
                                    <Card.Header className="text-center"><h5>Porcentaje alcanzado grupal</h5></Card.Header>
                                    <Card.Body>
                                        {loading && loadingText}
                                        {!loading &&
                                            <Chart
                                                {...bar}
                                                series={series}
                                                options={{
                                                    ...bar.options,
                                                    ...area.options,
                                                }}
                                            />
                                        }
                                    </Card.Body>
                                </Card>

                                <br/>
                                {




                                }

                            </>
                        ):
                        <></>
                    :<></>

                }

                {
                    buttonBool1 == 1?
                    <>
                        <br/>
                        <Row>

                            <Col>
                                {
                                    series?(
                                            <Card  className="shadow-none " style={{maxWidth: 300 ,minWidth:400, marginLeft:"auto", marginRight:"auto" }}>
                                                <Card.Header className="text-center"><h5>Matemáticas</h5></Card.Header>
                                                <Card.Body>
                                                    {loading2 && loadingText}
                                                    {!loading2 &&
                                                        <Chart
                                                            {...bar}
                                                            series={matematicas}
                                                            options={{
                                                                ...bar.options,
                                                                ...optionsMatematica.options,
                                                            }}
                                                        />
                                                    }

                                                </Card.Body>
                                            </Card>
                                        ):
                                        <></>
                                }
                            </Col>
                            <Col>
                                {
                                    lenguaje?(
                                            <Card  className="shadow-none" style={{maxWidth: 300 ,minWidth:400 ,marginLeft:"auto", marginRight:"auto" }}>
                                                <Card.Header className="text-center"><h5>Lenguaje</h5></Card.Header>
                                                <Card.Body>
                                                    {loading2 && loadingText}
                                                    {!loading2 &&
                                                        <Chart
                                                            {...bar}
                                                            series={lenguaje}
                                                            options={{
                                                                ...bar.options,
                                                                ...optionslenguaje.options,
                                                            }}
                                                        />
                                                    }

                                                </Card.Body>
                                            </Card>
                                        ):
                                        <></>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {
                                    optionsNaturales.options.xaxis.categories.length>0?(
                                            <Card  className="shadow-none" style={{maxWidth: 300 ,minWidth:400  ,marginLeft:"auto", marginRight:"auto" }}>
                                                <Card.Header className="text-center"><h5>Ciencias Naturales</h5></Card.Header>
                                                <Card.Body>
                                                    {loading2 && loadingText}
                                                    {!loading2 &&
                                                        <Chart
                                                            {...bar}
                                                            series={naturales}
                                                            options={{
                                                                ...bar.options,
                                                                ...optionsNaturales.options,
                                                            }}
                                                        />
                                                    }

                                                </Card.Body>
                                            </Card>
                                        ):
                                        <></>
                                }
                            </Col>
                            <Col>
                                {
                                    optionsAre4.options.xaxis.categories.length > 0?(
                                            <Card  className="shadow-none" style={{maxWidth: 300 ,minWidth:400  ,marginLeft:"auto", marginRight:"auto" }}>
                                                <Card.Header className="text-center"><h5>Competencias Ciudadanas</h5></Card.Header>
                                                <Card.Body>
                                                    {loading2 && loadingText}
                                                    {!loading2 &&
                                                        <Chart
                                                            {...bar}
                                                            series={are4}
                                                            options={{
                                                                ...bar.options,
                                                                ...optionsAre4.options,
                                                            }}
                                                        />
                                                    }

                                                </Card.Body>
                                            </Card>
                                        ):
                                        <></>
                                }
                            </Col>
                        </Row>
                    </>:<></>
                }
                {
                    buttonBool1 == 2?
                    <>

                        {
                            Listening?
                                <Card  className="shadow-none" style={{maxWidth: 450 , margin:"auto"}}>
                                    <Card.Header className="text-center"><h5>Listening and Vocabulary</h5></Card.Header>
                                    <Card.Body>
                                        {loading && loadingText}
                                        {!loading &&
                                            <Chart
                                                {...barWorldView}
                                                series={Listening}
                                                options={{
                                                    ...barWorldView.options,
                                                    ...worldviewCategories.options,
                                                }}

                                            />
                                        }
                                    </Card.Body>
                                </Card>
                                :<></>

                        }
                        <br/>

                        {
                            reading?

                                <Card  className="shadow-none" style={{maxWidth: 450 , margin:"auto"}}>
                                    <Card.Header className="text-center"><h5>Reading</h5></Card.Header>
                                    <Card.Body>
                                        {loading && loadingText}
                                        {!loading &&
                                            <Chart
                                                {...barWorldView}
                                                series={reading}
                                                options={{
                                                    ...barWorldView.options,
                                                    ...worldviewCategories.options,
                                                }}

                                            />
                                        }
                                    </Card.Body>
                                </Card>:<></>
                        }
                        <br/>
                        {
                            general?
                                <Card  className="shadow-none" style={{maxWidth: 450 , margin:"auto"}}>
                                    <Card.Header className="text-center"><h5>General</h5></Card.Header>
                                    <Card.Body>
                                        {loading && loadingText}
                                        {!loading &&
                                            <Chart
                                                {...barWorldView}
                                                series={general}
                                                options={{
                                                    ...barWorldView.options,
                                                    ...worldviewCategories.options,
                                                }}

                                            />
                                        }
                                    </Card.Body>
                                </Card>:<></>
                        }
                    </>:<></>
                }
                <br/>
                <br/>
            </Col>
        </div>
        </>
    );

};

export default TribeXuaii;