// @flow 
import * as React from 'react';
import FilterXuaii from "../Componets/FilterXuaii";
import {Card, Col, Row} from "react-bootstrap";
import Chart from "react-apexcharts";
import bar from "../Chart/chartXuaiiAreGroup";
import {useEffect, useState} from "react";
import {api, apiEva, apiEvaLocal} from "../../../utils/api";

import {
    AreaDataXuaiiGrupal,
    TypePropsDirective,
    CompetencyDataXuaiiGrupalCoordi,
    worldviewXuaii,
    TypeCampus
} from "../types";

import useCoordi from "../../Teacher/useCoordi";
import SqueareLoader from "../../Loaders/SqueareLoader";
import barWorldView from "../Chart/worldViewXuaii";
import FilterHistoryXuaii from "../Componets/FilterHistoryXuaii";
import DirectiveAllCampus from "../Componets/DirectiveAllCampus";
import {Link} from "react-router-dom";
import 'src/Style/Eva/Xuaii/xuaiiTribe.scss';
import useCurrentYear from "../Componets/UseCurrentYear";
import {useSelector} from "../../../store/reducer";
import {useDispatch} from "react-redux";
import {setAreaCoordi, setAreaGroup, setCompetencyCoordi, setEnglishCoordi} from "../../../redux/actions";
import FilterProgress from "../Componets/FilterProgress";
import OptionsFilter from "../Componets/OptionsFilter";
import {getUser, UserInterface} from "../../../utils/user";


interface Data {
    [key: string]: {
        [key: string]: {
            [key: string]: number;
        };
    };
}

const CoordinadorXuaiiGeneral = () => {
    const test = useSelector((state) => state.XuaiiCoordiReducer)
    const dispatch = useDispatch();


    //REDUX =====================================
    const Redux = useSelector((state) => state.DataRoles)
    //===========================================

    const [campus, setCampus] = useState<TypeCampus>(
        {
            name: "",
            id: 0,
        },
    );
    const {
        user,
        data
    } = useCoordi();

    const {
        currentYear
    } = useCurrentYear();


    const [year, setYear] = useState<number>(0);
    const [value, setValue] = useState<string>("entry");

    //Institution ID
    const [idCampus, setIdCampus] = useState<number>(0);

    //Bool AREA COMPETENCY==========================================>
    const [buttonBool1, setButtonBool1] = useState<number>(0);


    const [dataArea, setDataArea] = useState<[AreaDataXuaiiGrupal]>([
        {
            topic: "",
            correctPerformance: 0,
            incorrectPerformance: 0
        }
    ]);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingText, setLoadingText] = useState('');
    const [loadingCompetency, setLoadingCompetency] = useState<boolean>(true);

    const [area, setArea] = useState({
        options: {
            xaxis: {
                categories: [],
                title: {
                    text: 'Competencia',
                    style: {
                        fontWeight: 200,
                        fontSize: '15px',

                    }
                }
            }
        }
    });
    const [series, setSeries] = useState<ApexAxisChartSeries>();

//competency

    //ChartCompetency
    const [dataCompentecy, setDataCompetency] = useState<[CompetencyDataXuaiiGrupalCoordi]>([{
        area: '',
        competency: '',
        correct_performance: 0,
        count: 0,
        grado: '',
        incorrect_performance: 0
    }]);

    const [matematicas, SetMatematicas] = useState<ApexAxisChartSeries>();
    const [lenguaje, Setlenguaje] = useState<ApexAxisChartSeries>();
    const [naturales, SetNaturales] = useState<ApexAxisChartSeries>();
    const [are4, SetAre4] = useState<ApexAxisChartSeries>();
    const [optionslenguaje, setOptionslenguaje] = useState({
        options: {
            xaxis: {
                categories: [],
                title: {
                    text: 'Competencia',
                    style: {
                        fontWeight: 200,
                        fontSize: '15px',

                    }
                }
            }
        }
    });
    const [optionsMatematica, setOptionsMatematica] = useState({
        options: {
            xaxis: {
                categories: [],
                title: {
                    text: 'Grados',
                    style: {
                        fontWeight: 200,
                        fontSize: '15px',
                    }
                }
            }
        }
    });
    const [optionsNaturales, setOptionsNaturales] = useState({
        options: {
            xaxis: {
                categories: [],
                title: {
                    text: 'Competencia',
                    style: {
                        fontWeight: 200,
                        fontSize: '15px',

                    }
                }
            }
        }
    });
    const [optionsAre4, setOptionsAre4] = useState({
        options: {
            xaxis: {
                categories: [],
                title: {
                    text: 'Competencia',
                    style: {
                        fontWeight: 200,
                        fontSize: '15px',

                    }

                }
            }
        }
    });

    const [institutionPeriod, setInstitutionPeriod] = useState(0);
    const [queryGroup, setGroupQuery] = useState(0);
    const [userV2, setUser] = React.useState<UserInterface>();


    //Worldview
    const [worldview, setWorldview] = useState<worldviewXuaii>();

    const [Listening, setListening] = useState<ApexAxisChartSeries>();
    const [general, setGeneral] = useState<ApexAxisChartSeries>();
    const [reading, setReading] = useState<ApexAxisChartSeries>();
    const [worldviewCategories, setWorldviewCategories] = useState({
        options: {
            xaxis: {
                categories: ['PRE A1', 'A1', 'A2', 'B1', 'B2'],
                title: {
                    text: 'Nivel',
                    style: {
                        fontWeight: 200,
                        fontSize: '15px',

                    }
                },
            },
        },
    });


    useEffect(() => {
        const Year = currentYear[currentYear.length - 1]
        setYear(Year);
    }, [currentYear])

    useEffect(() => {

        const user: UserInterface = getUser();
        if (user.roles !== 'Coordinador') setUser(user);
        api.get("group_mentors_user/getGroup/"+user?.id).then((response) => {
            setGroupQuery(response.data.group.id)
        }).catch(() => {
            setLoading(true);
            // setLoadingText('Sin datos disponibles.');
        })

    }, []);


    useEffect(() => {
        if (year === 0 || year === undefined) return;

        setLoading(true);
        setLoadingText('Espera, cargando datos.');

        let period = 0
        if(year === 2025 && value === 'output') {
            period = 5
        }else if(year === 2025 && value === 'entry'){
            period = 4
        }else if(year === 2024 && value === 'output') {
            period = 3
        }else if(year === 2024 && value === 'entry'){
            period = 2
        }else if(year === 2023 && value === 'output'){
            period = 1
        }else{
            period = 3
        }

        if(data.institution?.id !== 0){
            apiEva.get("institution-periods/data/institution/"+data.institution?.id+"/period/"+period).then((response) => {
                setInstitutionPeriod(response.data);
            }).catch(() => {
                setLoading(true);
                // setLoadingText('Sin datos disponibles.');
            })

        }else{
            apiEva.get("/viajeros-xuaii/coordinador/"+user?.id).then((response) => {
                const institution = response.data.institutionId;
                apiEva.get("institution-periods/data/institution/"+institution+"/period/"+period).then((response) => {
                    setInstitutionPeriod(response.data);
                }).catch(() => {
                    setLoading(true);
                    // setLoadingText('Sin datos disponibles.');
                })
            }).catch(() => {
                setLoading(true);
                // setLoadingText('Sin datos disponibles.');
            })
        }



    }, [idCampus,year, value])

    useEffect(() => {
        if (idCampus !== 0) {
            if (year === 0 || year === undefined) return;

            setLoading(true);
            setLoadingText('Espera, cargando datos.');

            const params = {
                userId: idCampus,
                type: value,
                year: year,
                period: institutionPeriod
            }
            if (Object.keys(test.areaCoordi).length !== 0 && test.areaCoordi?.[idCampus]?.[year]?.[value] && buttonBool1 === 0) {
                const data = test.areaCoordi[idCampus][year][value];
                setDataArea(data);
                setLoading(false);
            } else if (buttonBool1 === 0) {
                apiEva
                    .patch<[AreaDataXuaiiGrupal]>('responses-xuaii/center/topic/performance/', params)
                    .then((response) => {
                        const data = response.data
                        setDataArea(data);
                        dispatch(setAreaCoordi(data, idCampus, year, value));
                        setLoading(false);
                    })
                    .catch(() => {
                        setLoading(true);
                        setLoadingText('Sin datos disponibles.');
                    })
            }
            // if (Object.keys(test.competencyCoordi).length !== 0 && test.competencyCoordi?.[idCampus]?.[year]?.[value] && buttonBool1 === 1) {
            if (false) {
                const data = test.competencyCoordi[idCampus][year][value];
                setDataCompetency(data);
                setLoading(false);
            } else if (buttonBool1 === 1) {
                apiEva
                    .patch<[CompetencyDataXuaiiGrupalCoordi]>('responses-xuaii/center/competency/performance/optimized/', params)
                    .then((response) => {
                        const data = response.data
                        setDataCompetency(data);
                        dispatch(setCompetencyCoordi(data, idCampus, year, value));
                        setLoading(false);
                    })
                    .catch(() => {
                        setLoading(true);
                        setLoadingText('Sin datos disponibles.');
                    })
                setLoading(true);
                // setLoadingText('Sin datos disponibles.');
            }
            if (Object.keys(test.englishCoordi).length !== 0 && test.englishCoordi?.[idCampus]?.[year]?.[value] && buttonBool1 === 2) {
                const data = test.englishCoordi[idCampus][year][value];
                setWorldview(data);
                setLoading(false);
            } else if (buttonBool1 === 2) {
                apiEva
                    .patch('responses-xuaii/center/worldview/performance/', params)
                    .then((response) => {
                        const data = response.data
                        setWorldview(data);
                        dispatch(setEnglishCoordi(data, idCampus, year, value));
                        setLoading(false);
                    })
                    .catch(() => {
                        setLoading(true);
                        setLoadingText('Sin datos disponibles.');
                    })
            }
        }

    }, [user, userV2, idCampus, institutionPeriod, buttonBool1])

    useEffect(() => {
        setLoading(true);
        setLoadingCompetency(true);

        if (campus != undefined && campus?.name !== '' && campus.id != 0) {
            setIdCampus(campus?.id);
        }
        else if (Redux?.institution?.id){
            setIdCampus(Redux?.institution?.id);
        }
        else if (data?.institution?.id != 0) {
            setIdCampus(data?.institution?.id);
        }

    }, [data, campus])

    let objetComplet = {
        'Ciencias Naturales': [],
        'Competencias Ciudadanas': [],
        'Lenguaje': [],
        'Matematicas': []
    };

    useEffect(() => {

        const organizedData = dataArea.reduce((acc: any, item) => {
            const [subject, grade] = item.topic.split('_');
            if (!acc[grade]) {
                acc[grade] = {};
            }
            acc[grade][subject] = [Math.round(item.correctPerformance)]

            return acc;
        }, {})

        for (let i = 3; i <= 11; i++) {
            const grade = i.toString();
            if (!organizedData[grade]) {
                organizedData[grade] = {
                    "Ciencias Naturales": [0],
                    "Competencias Ciudadanas": [0],
                    "Lenguaje": [0],
                    "Matematicas": [0]
                };
            }
        }

        for (const itemKey in organizedData) {
            if (Object.keys(organizedData[itemKey]).length < 4) {

                const KeysIn = Object.keys(organizedData[itemKey]);
                const keysCompleto = Object.keys(objetComplet);

                const only = keysCompleto.filter(clave => !KeysIn.includes(clave));
                only.forEach((item) => {
                    organizedData[itemKey][item] = [0]

                })
            }
        }
        const Lenguaje = [];
        const Matematicas = [];
        const CienciasNaturales = [];
        const CompetenciasCiudadanas = [];

        for (const key in organizedData) {
            let value = organizedData[key];
            for (const keyKey in value) {

                switch (keyKey) {
                    case 'Lenguaje':
                        Lenguaje.push(value[keyKey][0])
                        break;
                    case 'Matematicas':
                        Matematicas.push(value[keyKey][0])
                        break;
                    case 'Ciencias Naturales':
                        CienciasNaturales.push(value[keyKey][0])
                        break;
                    case 'Competencias Ciudadanas':
                        CompetenciasCiudadanas.push(value[keyKey][0])
                        break;
                }
            }
        }

        const categorie: any = [['3'], ['4'], ['5'], ['6'], ['7'], ['8'], ['9'], ['10'], ['11']];
        const dataSeries: ApexAxisChartSeries = [
            {name: "Lenguaje", data: Lenguaje},
            {name: "Matemáticas", data: Matematicas},
            {name: "Ciencias Naturales", data: CienciasNaturales},
            {name: "Competencias Ciudadanas", data: CompetenciasCiudadanas},
        ]

        setArea(() => ({
            options: {
                xaxis: {
                    categories: categorie,
                    title: {
                        text: 'Grados\n\n\n\nÁrea',
                        style: {
                            fontWeight: 200,
                            fontSize: '15px',

                        }
                    }
                }
            }
        }))

        setSeries(dataSeries)

    }, [dataArea])


    //COMPETENCY ====================================================>
    useEffect(() => {

        const DataCompetency: Data = dataCompentecy.reduce((acc: any, item) => {
            if (!acc[item.area]) {
                acc[item.area] = {};
            }

            if (!acc[item.area][item.competency]) {
                acc[item.area][item.competency] = {};
            }
            acc[item.area][item.competency][item.grado] = Math.round(item.correct_performance);

            const grados: string[] = Array.from({length: 10}, (_, i) => (i + 2).toString());
            grados.forEach((grado) => {
                if (!(grado in acc[item.area][item.competency])) {
                    acc[item.area][item.competency][grado] = 0;
                }
            });

            return acc;
        }, {})
        const processData = (area: string, data: React.SetStateAction<any>, competency: string, setState: React.SetStateAction<any>, optionsState: React.SetStateAction<any>) => {
            const values = [];
            const competencyValues = [];
            for (const key in data) {

                let value = data[key]
                if (key === area) {
                    for (const valueKey in value) {
                        let temDate = []
                        let temCompetency = "";

                        temCompetency = valueKey;
                        for (const valueKeyKey in value[valueKey]) {
                            temDate.push(value[valueKey][valueKeyKey]);
                        }
                        values.push({
                            name: temCompetency, data: temDate
                        })

                    }
                }


            }
            const series = [{name: area, data: values}];
            setState(values);

            optionsState({
                options: {
                    xaxis: {
                        categories: ['2', '3', '4', '5', '6', '7', '8', '9', '10', '11',],
                        title: {
                            text: 'Grados',
                            style: {
                                fontWeight: 200,
                                fontSize: '15px',

                            }
                        },
                    },
                },
            });
        };
        const areas = ['Matematicas', 'Lenguaje', 'Ciencias Naturales', 'Competencias Ciudadanas'];
        const stateVariables = [
            [SetMatematicas, setOptionsMatematica],
            [Setlenguaje, setOptionslenguaje],
            [SetNaturales, setOptionsNaturales],
            [SetAre4, setOptionsAre4],
        ];

        areas.forEach((area, index) => {
            const [setState, optionsState] = stateVariables[index];
            processData(area, DataCompetency, "", setState, optionsState);
        });


    }, [dataCompentecy])


    useEffect(() => {
        const stateVariables = {
            "listening_and_Vocabulary": setListening,
            "general": setGeneral,
            "reading": setReading,
        };

        for (const key in worldview) {
            let value = worldview[key]
            let series = [];
            let state = stateVariables[key as keyof typeof stateVariables];
            for (const valueKey in value) {
                series.push(value[valueKey])
            }
            state([{name: key, data: series}])
        }
    }, [worldview])

    return (
        <>
            <FilterProgress
                setValue={setValue}
                setYear={setYear}
                setCampus={setCampus}
            />
            <div className="BodyXuaiiTribe" id="">
                <OptionsFilter
                    style="Left-Nav"
                    setButtonBool1={setButtonBool1}
                    english={true}
                    campusName={campus.name}
                />
                {
                    <Col>
                        {campus.name === 'Todas las sedes' &&
                            <DirectiveAllCampus
                                year={year}
                                value={value}
                            />
                        }
                        {
                            buttonBool1 === 0 && campus.name !== 'Todas las sedes' ?
                                series ? (
                                        <>
                                            <br/>

                                            <>
                                                <Card className="shadow-none" style={{maxWidth: 900 ,margin: "auto"}}>

                                                    {series[0].data.length > 1 &&
                                                        <>
                                                            <Card.Header className="text-center"><h5>Porcentaje alcanzado
                                                                Sede</h5></Card.Header>
                                                            <Card.Body>
                                                                {loading && loadingText}
                                                                {!loading &&
                                                                    <Chart
                                                                        {...bar}
                                                                        series={series}
                                                                        options={{
                                                                            ...bar.options,
                                                                            ...area.options,
                                                                        }}
                                                                    />
                                                                }
                                                            </Card.Body>
                                                        </>

                                                    }
                                                </Card>
                                            </>
                                            <br/>
                                            {
                                                /*
                                               <Excel/>

                                                 */

                                            }
                                        </>
                                    ) :
                                    <></>
                                : <></>
                        }
                        {
                            buttonBool1 === 1 && campus.name !== 'Todas las sedes' ?
                                <>
                                    <br/>
                                    <Row>
                                        <Col>
                                            {
                                                matematicas ? (
                                                        <Card className="shadow-none "
                                                              style={{maxWidth: 900, minWidth: 900, margin: "2% auto",}}>
                                                            <>
                                                                <Card.Header className="text-center"><h5>Matemáticas</h5>
                                                                </Card.Header>
                                                                <Card.Body>
                                                                    {loading && loadingText}
                                                                    {!loading &&
                                                                        <Chart
                                                                            {...bar}
                                                                            series={matematicas}
                                                                            options={{
                                                                                ...bar.options,
                                                                                ...optionsMatematica.options,
                                                                            }}
                                                                        />
                                                                    }
                                                                </Card.Body>
                                                            </>

                                                        </Card>
                                                    ) :
                                                    <></>
                                            }
                                        </Col>
                                        {
                                            <Col>
                                                {
                                                    lenguaje ? (
                                                            <Card className="shadow-none"
                                                                  style={{maxWidth: 900, minWidth: 900, margin: "2% auto"}}>
                                                                <>
                                                                    <Card.Header className="text-center"><h5>Lenguaje</h5>
                                                                    </Card.Header>
                                                                    <Card.Body>
                                                                        {loading && loadingText}
                                                                        {!loading &&
                                                                            <Chart
                                                                                {...bar}
                                                                                series={lenguaje}
                                                                                options={{
                                                                                    ...bar.options,
                                                                                    ...optionslenguaje.options,
                                                                                }}
                                                                            />
                                                                        }
                                                                    </Card.Body>
                                                                </>
                                                            </Card>
                                                        ) :
                                                        <></>
                                                }
                                            </Col>

                                        }

                                    </Row>
                                    {

                                        <Row>
                                            <Col>
                                                {
                                                    naturales ? (
                                                            <Card className="shadow-none"
                                                                  style={{maxWidth: 900, minWidth: 900, margin: "2% auto"}}>
                                                                <>
                                                                    <Card.Header className="text-center"><h5>Ciencias
                                                                        Naturales</h5></Card.Header>
                                                                    <Card.Body>
                                                                        {loading && loadingText}
                                                                        {!loading &&
                                                                            <Chart
                                                                                {...bar}
                                                                                series={naturales}
                                                                                options={{
                                                                                    ...bar.options,
                                                                                    ...optionsNaturales.options,
                                                                                }}
                                                                            />
                                                                        }
                                                                    </Card.Body>
                                                                </>
                                                            </Card>
                                                        ) :
                                                        <></>
                                                }
                                            </Col>
                                            <Col>
                                                {
                                                    are4 ? (
                                                            <Card className="shadow-none"
                                                                  style={{maxWidth: 900, minWidth: 900, margin: "2% auto"}}>
                                                                <>
                                                                    <Card.Header className="text-center"><h5>Competencias
                                                                        Ciudadanas</h5></Card.Header>
                                                                    <Card.Body>
                                                                        {loading && loadingText}
                                                                        {!loading &&
                                                                            <Chart
                                                                                {...bar}
                                                                                series={are4}
                                                                                options={{
                                                                                    ...bar.options,
                                                                                    ...optionsAre4.options,
                                                                                }}
                                                                            />
                                                                        }
                                                                    </Card.Body>
                                                                </>
                                                            </Card>
                                                        ) :
                                                        <></>
                                                }
                                            </Col>
                                        </Row>

                                    }

                                </> : <></>
                        }
                        {
                            buttonBool1 === 2 && campus.name !== 'Todas las sedes' ?
                                <>
                                    <br/>

                                    {
                                        Listening ?
                                            <Card className="shadow-none" style={{maxWidth: 450, margin: "auto"}}>
                                                <Card.Header className="text-center"><h5>Listening and Vocabulary</h5>
                                                </Card.Header>
                                                <Card.Body>
                                                    {loading && loadingText}
                                                    {!loading &&
                                                        <Chart
                                                            {...barWorldView}
                                                            series={Listening}
                                                            options={{
                                                                ...barWorldView.options,
                                                                ...worldviewCategories.options,
                                                            }}
                                                        />
                                                    }
                                                </Card.Body>
                                            </Card>
                                            : <></>

                                    }
                                    <br/>
                                    {
                                        reading ?

                                            <Card className="shadow-none" style={{maxWidth: 450, margin: "auto"}}>
                                                <Card.Header className="text-center"><h5>Reading</h5></Card.Header>
                                                <Card.Body>
                                                    {loading && loadingText}
                                                    {!loading &&
                                                        <Chart
                                                            {...barWorldView}
                                                            series={reading}
                                                            options={{
                                                                ...barWorldView.options,
                                                                ...worldviewCategories.options,
                                                            }}
                                                        />
                                                    }
                                                </Card.Body>
                                            </Card> : <></>
                                    }
                                    <br/>
                                    {
                                        general ?
                                            <Card className="shadow-none" style={{maxWidth: 450, margin: "auto"}}>
                                                <Card.Header className="text-center"><h5>General</h5></Card.Header>
                                                <Card.Body>
                                                    {loading && loadingText}
                                                    {!loading &&
                                                        <Chart
                                                            {...barWorldView}
                                                            series={general}
                                                            options={{
                                                                ...barWorldView.options,
                                                                ...worldviewCategories.options,
                                                            }}
                                                        />
                                                    }
                                                </Card.Body>
                                            </Card> : <></>
                                    }
                                </> : <></>
                        }
                    </Col>
                }
            </div>
        </>

    );
};

export default CoordinadorXuaiiGeneral;